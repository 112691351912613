import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/column-layout.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="column-layout">
      <div className="column-layout__main">
        <div className="column-layout__centered-content">
          <h1>Page not found</h1>
          <p>How on earth did you end you end up here?</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
